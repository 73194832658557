import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Loading } from "../../Components";
import { useParams, Link } from "react-router-dom";
import { useLazyGetStatusQuery } from "../../redux/API/orderApiSlice";

const Fail = () => {
  const { id } = useParams();
  const [getStatus, { data, isLoading }] = useLazyGetStatusQuery();
  const navigate = useNavigate();

  const fetchStatus = async () => {
    try {
      await getStatus(id).unwrap();
      // console.log(data);
    } catch (error) {
      // console.log(error);
      navigate("/orders");
      toast(`${error.data.message}`, {
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [data]);
  return (
    <div className="flex justify-center">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col items-center justify-around bg-white h-96 rounded-xl p-10">
          <svg
            fill="#F44336"
            width="320px"
            height="320px"
            viewBox="0 -8 528 528"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>fail</title>
            <path d="M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM264 288L328 352 360 320 296 256 360 192 328 160 264 224 200 160 168 192 232 256 168 320 200 352 264 288Z" />
          </svg>
          <h1 className="font-bold text-3xl">Что-то пошло не так!</h1>
          <p className="text-lg text-center font-sans">
            Платеж {data && data.payment_status === "not paid" && "не поступил"}
            {data && data.payment_status === "declined" && "отклонен"},
            повторите пожалуйста попытку!
          </p>

          <div className="flex justify-around w-full mt-5">
            <Link to="/orders">
              <Button pay>Мои заказы</Button>
            </Link>
            <Link to="/">
              <Button pay>На главную</Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Fail;
