import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Button, OrderStatus } from "../";

import style from "./ordersTable.module.scss";

const OrderTable = ({ orders, onCancelOrder, onPayOrder }) => {
  const [width, setWidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    const handleResizeWindow = () =>
      setWidth(document.documentElement.clientWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);

  //   console.log(orders);

  return (
    <div className="w-full">
      {width <= 900 ? (
        <>
          {orders &&
            orders.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex flex-col w-full bg-white rounded-xl p-4 font-sans my-2"
                >
                  <div className="flex justify-between">
                    <b>№ {item.id}</b>
                    <b>{item.taking_amount} ₽</b>
                  </div>
                  <div className="flex justify-between mt-5">
                    <b>{item.object}</b>
                    <span>
                      {" "}
                      {format(
                        new Date(item.created_datetime),
                        "dd.MM.yyyy HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="mt-5">
                    <p>1. {item.adress_from}</p>
                    <p>2. {item.adress_where}</p>
                  </div>
                  <div className="flex justify-between items-center mt-5">
                    <b>
                      {<OrderStatus status={item.dostavista_order_status} />}
                    </b>
                    {item.payment_status === "not paid" &&
                      item.payments_method === "card" &&
                      item.dostavista_order_status !== "canceled" && (
                        <Button onClick={() => onPayOrder(item.id)} pay>
                          Оплатить
                        </Button>
                      )}
                    {item.payment_status === "declined" && (
                      <Button onClick={() => onPayOrder(item.id)} cancel>
                        Оплатить
                      </Button>
                    )}
                    {item.payment_status === "error" && (
                      <Button onClick={() => onPayOrder(item.id)} cancel>
                        Оплатить
                      </Button>
                    )}

                    {item.dostavista_order_status === "canceled" ? (
                      <Link to="/create-order">
                        <Button repeat>Повторить</Button>
                      </Link>
                    ) : (
                      <Button onClick={() => onCancelOrder(item.id)} cancel>
                        Отменить
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        <table className={style.table}>
          <thead>
            <tr>
              <th>Заказ</th>
              <th>Создан</th>
              <th>Статус</th>
              <th>Сумма</th>
              <th>Адрес доставки</th>
              <th>Способ оплаты</th>
              <th>Статус оплаты</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((item) => {
              return (
                <tr key={item.id}>
                  <td>№ {item.id}</td>
                  <td>
                    {format(
                      new Date(item.created_datetime),
                      "dd.MM.yyyy HH:mm"
                    )}
                  </td>
                  <td>
                    <OrderStatus status={item.dostavista_order_status} />
                  </td>
                  <td>{item.taking_amount} ₽</td>
                  <td>
                    1. {item.adress_from} <br /> <br /> 2. {item.adress_where}
                  </td>

                  <td>
                    {item.payments_method === "card" && "Онлайн"}
                    {item.payments_method === "cash" && "Курьеру"}
                  </td>
                  <td>
                    {item.payment_status === "not paid" && "Не оплачен"}
                    {item.payment_status === "approved" && "Оплачен"}
                    {item.payment_status === "declined" && "Отклонен"}
                    {item.payment_status === "error" && "Ошибка при оплате"}
                    {item.payment_status === "filtred" && "Платеж отклонен"}
                  </td>
                  <td>
                    {item.payment_status === "not paid" &&
                      item.payments_method === "card" &&
                      item.dostavista_order_status !== "canceled" && (
                        <Button onClick={() => onPayOrder(item.id)} pay>
                          Оплатить
                        </Button>
                      )}
                    {item.payment_status === "declined" && (
                      <Button onClick={() => onPayOrder(item.id)} cancel>
                        Оплатить
                      </Button>
                    )}
                    {item.payment_status === "error" && (
                      <Button onClick={() => onPayOrder(item.id)} cancel>
                        Оплатить
                      </Button>
                    )}

                    {item.dostavista_order_status === "canceled" ? (
                      <Link to="/create-order">
                        <Button repeat>Повторить</Button>
                      </Link>
                    ) : (
                      <Button onClick={() => onCancelOrder(item.id)} cancel>
                        Отменить
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OrderTable;
