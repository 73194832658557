import React from "react";

const OrderStatus = ({ status }) => {
  return (
    <>
      {status === "new" && "Создан"}
      {status === "Cancelled via API" && "Отменен"}
      {status === "canceled" && "Отменен"}
      {status === "available" && "Поиск курьера"}
      {status === "active" && "Выполняется"}
      {status === "completed" && "Завершен"}
    </>
  );
};

export default OrderStatus;
